@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400italic,700,700italic,900,900italic);

body {
  margin: 0;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato';
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
	border-bottom: 2px dashed #1890ff;
}
#components-table-demo-drag-sorting tr.drop-over-upward td {
	border-top: 2px dashed #1890ff;
}
.ant-table-column-sorters{
	display: inline-flex !important;
	align-items: center !important;
}
.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 500px;
  padding: 48px;
  overflow: auto;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}
.ant-modal-root .ant-modal-mask, .ant-drawer .ant-drawer-mask{
  animation-name: k3;
  animation-duration: 0.25s;
  animation-timing-function: ease;
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(4px);
}
dl{
	margin-bottom: 0.1rem !important;
}
.dlist{
  justify-content: space-between;
  display: flex;
}
.float-end{
  min-width: 250px;
}
.order-info-wrap,.float-end{
  font-family: 'Lato';
  font-size: 15px;
  font-weight: 500;
}
.table-lg{
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 500;
}
.tags-editor {
	padding: auto;
	border: 1px solid #0ecb81;
	border-radius: 5px;
	cursor: text;
	position: static;
	min-height: 3rem;
	overflow: auto;
}
.ant-select-selector{
	border: none !important;
}
.fullhalf {
  width: 49% !important;
  margin-right: 1% !important;
}

.fullhalf-right {
  width: 49% !important;
  margin-left: 1% !important;
  right: 0 !important;
  float: right;
}

.token-item.is-grey {background-color: #fafafa;border-color: #fafafa;color: #0ecb81;margin-top: 10px;margin-bottom: 10px;cursor:pointer}.token-item {display: inline;border-radius: 3px;padding: 8px;margin-right: 6px;margin-bottom: 6px;float: left;max-width: 100%;-webkit-touch-callout: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;background-color: #fafafa;border: 1px solid #b5b6b7;}.category-item-selected, .category-item-selected:hover, .dark .category-item-selected {background-color: #0ecb81 !important;}.category-item-selected {color: #fafafa !important;}

.btn-primary {
  background-color: #0ecb81 !important;
  border: 1px solid #0ecb81 !important;
}
ul.opendays{
	padding-left: 0 !important;
}

.logo{
	width: calc(100% - 18px);	
	height: 70px;	
	margin: 10px auto;
	margin-top: 30px;
	display: flex;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
}
.ant-menu-title-content{
	font-size: 16px;
}
.ant-form-item-no-bottom-margin {
  margin-bottom: 0;
}
.anticon{
	vertical-align: 0.125em !important;
}
/*
.ant-menu-item-active, .ant-menu-item-selected{
	border-radius: 50px;
	background-color: #2b3c19 !important;
}
.ant-layout-sider, .ant-menu, .ant-layout-header{
	background-color: #3e5725 !important;
}
.ant-menu-sub{
	background-color: #080a05 !important;
}
*/
@media (min-width: 736px) {
  .ant-drawer-content-wrapper {
    max-width: 473px !important;
  }
}
.ant-layout{
  font-family: 'Lato' !important;
}
.ant-table-content{
  width: 100%;
  overflow-x: auto;
}
.site-layout-background{
  padding: 24px;
}
._6wlw6o {
  margin-right: 473px;
  margin-bottom: 20px;
  margin-left: auto;
}

._ruof1q {
  padding-left: 10%;
  padding-right: 12.3%;
  margin-bottom: 40px;
}
._86m4 {
    flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    align-items: flex-start;
    -moz-box-align: start;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    align-items: center;
    -moz-box-align: center;
    display: flex;
    flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    position: sticky;
    top: 0px;
    background: white;
}

._oj9oqm {
  border-bottom: 3px solid #257a80 !important;  
}

._obgv77 {
	font-size: 14px;
    line-height: 1.29;
    padding: 11px 16px 12px;
    color: rgb(76, 76, 76);
    font-family: 'Lato';
    font-weight: 600;
    line-height: 1.5;
    border-bottom: 1px solid rgb(235, 233, 230);
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    text-decoration: none;
	cursor: pointer;
}

._1jx0i45 {
  margin-left: 0px;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
}

._as2sev {
  margin-left: 10%;
  margin-right: 10%;
  flex-grow: 1;
  -moz-box-flex: 1;
}

._yq7j8e {
  display: flex;
}

._yq7j8e:first-of-type [data-name="title"] {

    margin-top: 0px;

}

._rnsmcq {
  display: block;
  position: relative;
  top: -44px;
  visibility: hidden;
  height: 0px;
  width: 0px;
}

._18z5nku {
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  width: 100%;
}


._1gg3ue {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 700;
    display: flex;
    margin-left: 15px;
    color: rgb(24, 18, 24);
    text-transform: uppercase;
}

._1yc6rdp {
    font-size: 16px;
    line-height: 1.25;
    color: rgb(76, 76, 76);
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    -moz-box-align: center;
    background-color: rgb(204,220,205);
    margin: 0px 24px 18px 0px;
    padding: 10px;
}

._1dcg472 {
    margin: 0px 10px;
}

._1743i26 {
	display: flex;
    flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    background: none;
} 
._19hi95f {
    background: none;
    display: flex;
    flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    background: white;
    flex: 1 1 0%;
}

._194c2bm {
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(0, 0, 0, 0.1);
    margin-right: 24px;
    margin-bottom: 20px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    -moz-box-pack: justify;
    height: max-content;
    cursor: pointer;
    background: rgb(255, 255, 255);
    align-items: start;
    -moz-box-align: start;
    overflow: hidden;
}

._194c2bm:hover{
	border-width: 1px;
	border-color: rgb(86, 140, 91, 0.9);
	background-color: rgb(86, 140, 91, 0.1);
}

._15q0jjh {
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
}

._qsnllp {
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  justify-content: space-between;
  -moz-box-pack: justify;
  height: 100%;
}


._5lfk5c {
  display: none;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-left: medium none;
  border-bottom: medium none;
  width: calc(100% - 32px);
}

._1pbw5a2 {
    aspect-ratio: 1 / 1;
	display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
	height: 100%;
	min-width: 142px;
	width: 142px;
	border: medium none;
	margin: 0px;

}

._190hxbq {
  width: 100%;
  height: 100%;
}


._1n57n8z {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: rgb(24, 18, 24);
    padding-bottom: 4px;
    display: -webkit-box;
    height: 19px;
    max-height: 19px;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

._vlgzaz {
    
    font-family: 'Lato';
    font-weight: normal;
    font-size: 14px;
	height: 44px;
    line-height: 15px;
	max-height: 32px;
    color: rgb(115, 114, 128);
    display: -webkit-box;    
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 4px;
}

._lu8b5g {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgb(32, 30, 30);
  margin-top: 16px;
  margin-bottom: 0;
}
.M0cnph9mBkjQ7sKnRJoCn {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  box-sizing: border-box;
  width: 473px;
  height: 100vh;
  padding: 30px 56px 30px 40px;
  background: rgb(204,220,205); /*#f5f3f0;*/
}

@media (min-width: 1440px){
	.c5PRj4_tt33kyMy30ibSp {
	  line-height: 32px;
	  font-size: 24px;
	  font-weight: 900;
	}
}

@media (max-width: 1440px){
	._as2sev {
	  margin-left: 0;
	  margin-right: -20px;
	}
	._ruof1q {
	  padding-left: 0;
	  padding-right: 0;
	  margin-bottom: 40px;
	}
	
}

._2mfDffMxberUHEHnzJbsqf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 70%;
  text-align: center;
}
.c5PRj4_tt33kyMy30ibSp {
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  color: #181218;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebe9e6;
}
._3gouMfJy504Yjz5rHjoHpu {
  margin: 20px 0;
}
._1lSOQ6q2xcOkaxVT1smcn7 {
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}
._164jj58 {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  -moz-box-pack: justify;
  align-items: center;
  -moz-box-align: center;
  width: 100%;
  background: none;
  border: medium none;
}
._1e55dq5 {
  font-size: 14px;
  line-height: 1.15;
  color: rgb(76, 76, 76);
  font-family: 'Lato';
  font-weight: 500;
  margin-bottom: 0;
}

@media (min-width: 1100px){
	._2mfDffMxberUHEHnzJbsqf {
	  padding: 50px 0;
	}
}

@media (max-width: 1100px){
	._as2sev {
	  margin-right:-20px;
	}
	._6wlw6o{
		margin-right:0;
	}
	._ruof1q {
	  padding-left: 0;
	  padding-right: 0;
	}
	
	.M0cnph9mBkjQ7sKnRJoCn {
	  display: none;
	}
}

@media (max-width: 768px){
	._as2sev {
	  margin-right:0;
	}
	._6wlw6o{
	}
	
	.M0cnph9mBkjQ7sKnRJoCn {
	  display: none;
	}
	._1743i26 {
		display: flex;
		flex-direction: column;
		-moz-box-orient: horizontal;
		-moz-box-direction: normal;
		background: white;
	} 
	._194c2bm {
	  display: flex;
	  box-sizing: border-box;
	  justify-content: space-between;
	  -moz-box-pack: justify;
	  height: max-content;
	  cursor: pointer;
	  background: rgb(255, 255, 255);
	  align-items: start;
	  -moz-box-align: start;
	  overflow: hidden;
	  border-radius: 0;
	  border-left: 0;
	  border-right: 0;
	  margin-right: 0;
	  margin-bottom: 0;
	}
	.site-layout-background{
		padding: 0 !important;
	}
	
	._1yc6rdp {
		margin: 15px 2px 18px 0px;
	}
	._ruof1q {
		padding-left: 0;
		padding-right: 0;
	}
}


._1wozt5f {
  margin-right: 473px;
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
}

._14a9t3k {
  background: black;
}

._12atkkn {
  display: flex;
}

._1gbkmlt {

    background-image: url("https://slicelife.imgix.net/52152/photos/original/182433589_10158242616971728_6900528469122368924_n.jpg?crop=focalpoint&fp-x=0.5&fp-y=0.4&auto=compress,format&fit=crop&w=1300&h=350");
    background-position: center center;
    background-size: cover;
    display: flex;
    height: auto;
    width: 100%;
    object-fit: cover;
    min-height: 180px;
	flex-direction: column;
}

._11ochor {
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: multiply;
}
._bxoxda {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  justify-content: space-between;
  -moz-box-pack: justify;
}

._zdt81h {
  margin-left: 10% !important;
  display: flex;
  flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  align-items: start;
  -moz-box-align: start;
  margin: 50px 0px 40px;
}

._1dogarg {
  display: flex;
  align-items: flex-start;
  -moz-box-align: start;
  flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  max-width: 363px;
}

._5if1ut {
  max-width: 363px;
  box-shadow: rgb(104, 114, 130) 0px 2px 10px -3px;
  border-radius: 10px;
  display: flex;
  background: white;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

._dfgcxn {
  padding: 28px;
}

._nngzzd {

    left: 28px;
    top: 0px;
    max-height: 70px;
    max-width: 85px;
    position: absolute;
    left: 16px;
    top: 0px;
    transform: translateY(-60%);
    border-radius: 5px;
}
._1qulqfk {
  font-size: 22px;
  line-height: 1.2;
  color: rgb(24, 18, 24);
  font-family: 'Lato';
  font-weight: 500;
}

._8mmg2s {
  line-height: 1.2;
  color: rgb(104, 114, 130);
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  margin-right: 8px;
}

._g4kti3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -moz-box-align: center;
}

._63g2va {
    color: rgb(104, 114, 130);
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin-right: 12px;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
}

._xg6t39 {
  font-size: 8px;
  width: 35px;
  height: 14px;
  display: inline-flex;
  align-items: center;
  -moz-box-align: center;
  justify-content: center;
  -moz-box-pack: center;
  letter-spacing: 0.3px;
  color: rgb(46, 133, 64);
  border-radius: 3px;
  text-transform: uppercase;
  border: 1px solid rgb(46, 133, 64);
  font-weight: bold;
  font-family: 'Lato';
}

._1si8x2j {
  color: rgb(236, 79, 56);
  font-family: 'Lato';
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin-right: 8px;
}

._1ocjsgi {

    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px 0px;
    height: auto;
    max-height: calc(94 * var(--dvh, 1vh));
    top: 25px;
    border-radius: 4px;
    position: relative;
    width: 496px;
    animation-name: k7;
    animation-duration: 0.25s;
    animation-timing-function: ease;
    background-color: rgb(239, 239, 239);
    width: 100%;
    position: absolute;
    inset: 0px;
        top: 0px;
    z-index: 300;
    overflow: auto;

}

._mtl0xi {
  padding: 19px 20px 15px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(232, 232, 232);
  position: sticky;
  top: 0px;
  z-index: 100;
}

._1vqd63h {
  display: flex;
  justify-content: space-between;
  -moz-box-pack: justify;
}

._145yv6c {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Lato';
  line-height: 1.13;
  color: rgb(24, 18, 24);
}

._gex7fg {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0px;
  padding: 0px;
  border: medium none;
  background: none;
}

._3vqbuy {
  stroke: rgb(133, 133, 133);
}

._3b5y9o {
  display: flex;
  flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  position: relative;
  padding: 16px 20px;
  align-items: baseline;
  -moz-box-align: baseline;
  top: 0px;
}

._1gjet7t {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Lato';
  line-height: 1;
  color: rgb(24, 18, 24);
  width: auto;
}

._1198w56 {
  background-color: rgb(255, 255, 255);
  margin: 0px 16px;
  border-radius: 10px;
  box-shadow: rgba(213, 213, 213, 0.5) 0px 4px 4px 0px;
  padding: 0px 24px;
}

._1198w56 > div {
  padding: 16px 0px;
}
._1ci865u {
  display: flex;
  align-items: baseline;
  -moz-box-align: baseline;
  justify-content: space-between;
  -moz-box-pack: justify;
}

._1198w56 > div:not(:last-child) {
  border-bottom: 1px solid rgb(232, 232, 232);
}
._1198w56 > div {
  padding: 16px 0px;
}
._6957ij {
  margin-bottom: 0px;
}

._zk6ms9 {
  display: flex;
  position: relative;
  align-items: center;
  -moz-box-align: center;
}

._3a4fqr {
  cursor: pointer;
  display: block;
  opacity: 1;
  height: 16px;
  width: 16px;
  transition-duration: 0.125s;
  transition-timing-function: ease;
  transition-property: background-color, border-color, border-width;
}

._kc8zf8 {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(196, 196, 196);
  border-radius: 2px;
  width: 14px;
  height: 14px;
}

._2xN5JrQs45cPLY0r19pzdb[data-shift="true"] {

    padding-left: 10px;

}
._2xN5JrQs45cPLY0r19pzdb[data-clickable="true"] {

    cursor: pointer;

}
._2xN5JrQs45cPLY0r19pzdb[data-shift="true"] {

    padding-left: 10px;

}
._2xN5JrQs45cPLY0r19pzdb[data-clickable="true"] {

    cursor: pointer;

}

@media (min-width: 768px){

._2xN5JrQs45cPLY0r19pzdb {

    font-family: 'Lato';
    line-height: 1.29;
    font-size: 14px;
    font-weight: 400;

}
}
._2xN5JrQs45cPLY0r19pzdb {

    font-family: 'Lato';
    line-height: 1.5;
    font-size: 12px;
    font-weight: 400;
    color: #4c4c4c;
    margin-bottom: 0;
    position: relative;
    user-select: none;
    cursor: pointer;

}

@media (min-width: 768px){

._2xN5JrQs45cPLY0r19pzdb {

    font-family: 'Lato';
    line-height: 1.29;
    font-size: 14px;
    font-weight: 400;

}
}
._2xN5JrQs45cPLY0r19pzdb {

    font-family: 'Lato';
    line-height: 1.5;
    font-size: 12px;
    font-weight: 400;
    color: #4c4c4c;
    margin-bottom: 0;
    position: relative;
    user-select: none;
    cursor: pointer;

}

.T_8dt4t-Vv35swCnOs0VE {
  display: flex;
  flex-direction: row;
  align-items: center;
}

._1swrsi5 {
  font-size: 16px;
  line-height: 1.25;
  color: rgb(76, 76, 76);
  font-family: 'Lato' !important;
  font-weight: 500;
  font-size: 14px;
  margin-top: -4px;
}

._l8ik20 {
  padding-bottom: 16px;
}

._3RKZre7HIKkj8f6ugINYDV {
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  position: sticky;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  bottom: 0;
}

@media (min-width: 768px){

._1CwjZK_2Wbc4SetuxezVf9 {

    font-family: 'Lato';
    line-height: 22px;
    font-size: 14px;
    font-weight: 900;

}

}
._1CwjZK_2Wbc4SetuxezVf9 {

    font-family: 'Lato';
    line-height: 20px;
    font-size: 12px;
    font-weight: 900;
    background-color: #201e1e;
    border-radius: 4px;
    color: #fff;
    padding: 12px 24px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .25s ease,background-color .25s ease;

}
._2s2DfQ5MrOu2S-UPSvVNvV {

    width: 100%;

}
._1JdD97nX-WGcPASVXFJXKw {

    background: 0 0;
        background-color: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;

}
._10fyih4 {
  padding: 0px 16px 16px;
}

._1AD0LNP6McditinZd3spmP {
  font-family: 'Lato';
  line-height: 2.25;
  font-size: 12px;
  font-weight: 400;
  color: #4c4c4c;
}

._2-Zj0_LmMRG2bniShwniwv::placeholder {

    color: #adadad;

}

._2qEZI8AjL7lcUSB-3EHEMt {

    height: 42px;

}

@media (min-width: 768px){

	._2-Zj0_LmMRG2bniShwniwv {

		font-family: 'Lato';
		line-height: 1.29;
		font-size: 14px;
		font-weight: 400;

	}
}
._2-Zj0_LmMRG2bniShwniwv {

    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    appearance: none;
    resize: none;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    transition: border-color .125s ease;
    font-family: 'Lato';
    line-height: 1.25;
    font-size: 12px;
    font-weight: 400;
	color: #4c4c4c;
}

._1u7Ze1C1RwUW0dkalIQfvs[data-alwayson="true"] {

    display: inline;

}
._1u7Ze1C1RwUW0dkalIQfvs {

    font-family: 'Lato' !important;
    display: none;
    padding-left: .5em;
    user-select: none;

}

._yag5ji {
  display: flex;
  height: 16px;
  margin: 0px;
  align-items: center;
  -moz-box-align: center;
  color: rgb(76, 76, 76);
}

._1x9fdrg[data-checked="true"] {
  background-color: rgb(255, 255, 255);
  border-color: rgb(32, 30, 30);
}
._1x9fdrg {
  border-color: rgb(196, 196, 196);
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  min-height: 16px;
  overflow: hidden;
  transition-duration: 0.125s;
  transition-timing-function: ease;
  transition-property: border-color;
  min-width: 16px;
  position: relative;
}

._1jjm0xr {
  background-color: rgb(32, 30, 30);
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
  height: 8px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 8px;
}

._8n9wjm {

    opacity: 0;
    bottom: -100px;
    max-height: 0px;
    padding: 0px;
    transition: all 200ms ease-in 0s, padding 200ms ease-in 250ms, max-height 200ms ease-in 250ms;

}
._1fedyu0 {

    display: flex;
    position: sticky;
    bottom: 101px;
    background: rgb(253, 247, 241);
    color: rgb(0, 0, 0);
    border: 1px solid rgb(195, 174, 138);
    border-radius: 4px;
    padding: 16px;
    margin: 0px 16px;
    font-family: 'Lato';
    overflow: hidden;
    max-height: 500px;
    transition: all 400ms ease-out 200ms, padding 400ms ease-out 0s, max-height 400ms ease-out 0s;
}






.card-product-grid {
  margin-bottom: 20px;
}
[class*="card-product"] {
  border: 1px solid rgba(108, 117, 125, 0.25);
}
.card-product-grid .img-wrap {
  border-radius: 0.2rem 0.2rem 0 0;
  height: 220px;
}
[class*="card-product"] .img-wrap {
  text-align: center;
  display: block;
  overflow: hidden;
  position: relative;
  background: #f8f8f8;
}

[class*="card-product"] .img-wrap img {
  height: 100%;
  max-width: 100%;
  width: auto;
  display: inline-block;
  object-fit: cover;
}

.card-product-grid a.title {
  color: #6c757d;
  display: block;
  text-decoration: none;
}

.price {
  font-weight: 600;
}

.card-product-grid .info-wrap {
  padding: 1rem;
}




.order-list {
  max-width: 600px;
  margin: 0 auto;
}

.order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.order-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.order-comment {
  padding: 10px;
}

.order-number {
  font-size: 20px;
  font-weight: bold;
}

.order-ready-time {
  font-size: 14px;
  color: #888;
}

.order-item-name {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 600;
  color: #888;
}

.order-item-addons {
  margin-top: 4px;
  font-size: 14px;
  color: #888;
}

.order-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-status {
  font-weight: bold;
  padding: 8px;
  border-radius: 4px;
  margin-left: 8px;
}

.accepted {
  background-color: #e6f7ff;
  color: #1890ff;
}

.declined {
  background-color: #fff1f0;
  color: #f5222d;
}

.warned {
  background-color: #fff3cd;
  color: #b28700;
}

.succeed {
  background-color: #cdf8f3;
  color: #38761d;
}


.incsizespan_info {
  background-color: #e6f7ff;
  color: #1890ff;
  padding: 10px;
}

.incsizespan_success {
  background-color: #cdf8f3;
  color: #38761d;
  padding: 10px;
}

.incsizespan_error {
  background-color: #fff1f0;
  color: #f5222d;
  padding: 10px;
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
}

.ant-statistic {
  text-align: center;
}

canvas {
  max-width: 100%;
}
.fas{
	font-size: 25px;
	margin-left: -7px;
	display: flex;
}
.icontext{
	display: flex;
	justify-content: start;
}
.icontext>span{
	margin-right: 10px;
	display: flex;
    align-items: center;
	padding: 25px;
	height: 60px;
	width: 60px;
}

.flashing{
	background: linear-gradient(60deg, #0ecb81, #26d08d, #3ed59a, #56daa6, #6edfb3, #86e5c0, #9eeacc, #b6efd9, #cef4e5, #e6f9f2, #ffffff);
	animation: flash 1s ease infinite;
	background-size: 300% 300%;
}


@keyframes flash {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.not-found-heading {
  font-size: 5rem;
  color: #333;
  margin-bottom: 1rem;
}

.not-found-message {
  font-size: 2rem;
  color: #777;
}

.not-found-link {
  color: #fff;
  background-color: #007bff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.2rem;
}

.not-found-link:hover {
  background-color: #fff;
}

.not-found-link:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}



.search-area-button {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #000;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.contentInfo{
	font-family: "Lato";	
}

.contentInfo_selected{
	border: 5px solid #000 !important;
}

.pointer{
	font-family: "Lato";
	color: #1d3127;
	cursor: pointer;
	text-decoration: underline;
}


/* src/components/Message.css */
.message {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.message__user {
  flex-direction: row-reverse;
}

.message__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message__content {
  flex: 1;
}

.message__name {
  font-weight: bold;
  margin-right: 15px;
}

.message__timestamp {
  font-size: 12px;
  color: #777;
}

.chat {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
  background-color: #e5e5e5;
}
