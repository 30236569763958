.navContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid gray;
  position: sticky;
  top: 0;
  background-color: aliceblue;
  overflow-x: scroll;
}
.navlink {
  border-radius: 5px;
  padding: 10px 20px;
  background-color: burlywood;
  margin: 0 10px;
  min-width: 300px;
}
.inputText {
  height: 40px !important;
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  transition: all 0.2s;
}
.buttonText {
    height: 40px !important;  
    position: relative;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	cursor: pointer;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	user-select: none;
	touch-action: manipulation;
	line-height: 1.5714285714285714;
}
.site-space-compact-wrapper .site-input-split {
  background-color: #fff;
}

.site-space-compact-wrapper .site-input-right:not(.ant-input-rtl) {
  border-left-width: 0;
}

.site-space-compact-wrapper .site-input-right:not(.ant-input-rtl):hover,
.site-space-compact-wrapper .site-input-right:not(.ant-input-rtl):focus {
  border-left-width: 1px;
}

.site-space-compact-wrapper .site-input-right.ant-input-rtl {
  border-right-width: 0;
}

.site-space-compact-wrapper .site-input-right.ant-input-rtl:hover,
.site-space-compact-wrapper .site-input-right.ant-input-rtl:focus {
  border-right-width: 1px;
}

.site-space-compact-wrapper .site-input-left:not(.ant-input-rtl) {
  border-right-width: 0;
}

.site-space-compact-wrapper .site-input-left:not(.ant-input-rtl):hover,
.site-space-compact-wrapper .site-input-left:not(.ant-input-rtl):focus {
  border-right-width: 1px;
}

.site-space-compact-wrapper .site-input-left.ant-input-rtl {
  border-left-width: 0;
}

.site-space-compact-wrapper .site-input-left.ant-input-rtl:hover,
.site-space-compact-wrapper .site-input-left.ant-input-rtl:focus {
  border-left-width: 1px;
}

.hidden-file{
	border: 0!important;
	clip: rect(0 0 0 0)!important;
	height: 1px!important;
	margin: -1px!important;
	overflow: hidden!important;
	padding: 0!important;
	position: absolute!important;
	width: 1px!important;
}